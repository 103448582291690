@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Racing+Sans+One&display=swap');


:root {
    --horse-1: url("assets/subject/1.svg");
    --horse-2: url("assets/subject/2.svg");
    --horse-3: url("assets/subject/3.svg");
    --horse-4: url("assets/subject/4.svg");
    --horse-5: url("assets/subject/5.svg");
    --horse-6: url("assets/subject/6.svg");
    --horse-7: url("assets/subject/7.svg");
    --horse-8: url("assets/subject/8.svg");
    --horse-9: url("assets/subject/9.svg");
    --horse-10: url("assets/subject/10.svg");
    --fence: url("assets/fence.png");
    --fence-long: url("assets/fence_long.svg");
    --track-bg-01: url("assets/track-bg-001.svg");
    --track-bg-02: url("assets/track-bg-002.svg");
    --track-bg-03: url("assets/track-bg-003.svg");

    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 430px;
    --toastify-toast-background: var(--bs-success-bg-subtle);
    --toastify-toast-min-height: 100px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: 'Oswald', sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;


    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;


    --toastify-color-progress-light: linear-gradient(
            to right,
            #4cd964,
            #5ac8fa,
            #007aff,
            #34aadc,
            #5856d6,
            #ff2d55
    );

    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-13 16:25:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@-webkit-keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
}

@keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(.2);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }
}

@-webkit-keyframes rotate-in-center{0%{-webkit-transform:rotate(-360deg);transform:rotate(-360deg);opacity:0}100%{-webkit-transform:rotate(0);transform:rotate(0);opacity:1}}@keyframes rotate-in-center{0%{-webkit-transform:rotate(-360deg);transform:rotate(-360deg);opacity:0}100%{-webkit-transform:rotate(0);transform:rotate(0);opacity:1}}
@-webkit-keyframes rotate-out-center{0%{-webkit-transform:rotate(0);transform:rotate(0);opacity:1}100%{-webkit-transform:rotate(-360deg);transform:rotate(-360deg);opacity:0}}@keyframes rotate-out-center{0%{-webkit-transform:rotate(0);transform:rotate(0);opacity:1}100%{-webkit-transform:rotate(-360deg);transform:rotate(-360deg);opacity:0}}

@keyframes dondur {
    from {
        transform: rotate(0deg); /* Başlangıç açısı */
    }
    to {
        transform: rotate(360deg); /* Bitiş açısı */
    }
}

.rotate-in-center {
    animation: dondur 4s linear infinite;
    -webkit-animation: dondur 4s linear infinite;;
}

.rotate-out-center {
    -webkit-animation: rotate-out-center .6s cubic-bezier(.55, .085, .68, .53) both;
    animation: rotate-out-center .6s cubic-bezier(.55, .085, .68, .53) both
}

.horse_anim {
    /*-webkit-animation: bounce-left 0.8s both;
    animation: bounce-left 0.8s both;*/

    -webkit-animation: slide-in-blurred-top 0.8s both;
    animation: slide-in-blurred-top 0.8s both;
}

.running_line {
    background-image: var(--track-bg-02);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 100px;
    padding: 0;
}

.court {
    background: linear-gradient(to bottom, #8dc256, #8E6725);
}

/* The slider itself */
.slider {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 35px; /* Specified height */
    /*background: none;*/
    outline: none; /* Remove outline */
    background-color: rgba(43, 80, 10, 0);
    background-image: var(--fence-long);
    background-repeat: repeat-x;
    background-size: 150px;
    /*opacity: 0.7;*/ /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;

}

/* Mouse-over effects */
.slider:hover {
    opacity: 0.9; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider1::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */

    -webkit-animation: slide-in-blurred-top 0.8s infinite;
    animation: slide-in-blurred-top 0.8s infinite;
}


.slider1::-moz-range-thumb {
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */

    -webkit-animation: slide-in-blurred-top 0.8s infinite;
    animation: slide-in-blurred-top 0.8s infinite;
}

.slider2::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-2);;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider2::-moz-range-thumb {
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-2);;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider3::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-3);;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider3::-moz-range-thumb {
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-3);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider4::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-4);;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider4::-moz-range-thumb {
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-4);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider5::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-5);;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider5::-moz-range-thumb {
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-5);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}


.slider6::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-6);;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider6::-moz-range-thumb {
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-6);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}


.slider7::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-7);;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider7::-moz-range-thumb {
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}


.slider8::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-8);;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}

.slider8::-moz-range-thumb {
    width: 100px; /* Set a specific slider handle width */
    height: 100px; /* Slider handle height */
    background-image: var(--horse-8);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer; /* Cursor on hover */
    animation: bounce-right 2s infinite;
    -webkit-animation: bounce-right 2s infinite; /* Safari */
}


.fs-7 {
    font-size: 0.8rem !important;
}

.bg-success-light {
    background-color: #8dc256;
}

body {
    background-image: url("assets/stars-glitter.gif");
    background-repeat: repeat;
    background-color: #192819;
    background-blend-mode: hard-light;
}

.shadow-white {
    box-shadow: 0 0 30px whitesmoke;
}

.text-xsm {
    font-size: .675em
}

.header-text-blue {
    color: #8eaff1;
    font-size: 1.2rem;
    font-family: 'Racing Sans One', cursive;
}

.text-yellow-200-racing {
    letter-spacing: 0.1rem;
    font-size: 1.7rem;
    font-family: 'Racing Sans One', cursive;
    text-shadow: 0.1rem 0.1rem 0.2rem #1e1c00;
}

.text-description-200 {
    color: #2d6b2d;
    letter-spacing: 0.1rem;
    font-size: 0.9rem;
    font-family: 'Oswald', sans-serif;
    text-shadow: 0.1rem 0.1rem 0.2rem #1d6b1d;
}

.text-green-200 {
    color: #2d6b2d;
    letter-spacing: 0.1rem;
    font-family: 'Oswald', sans-serif;
    font-size: 0.70rem;
    text-shadow: 0.1rem 0.1rem 0.2rem #1d6b1d;
}

.text-shadow-sm {
    text-shadow: 1px 1px 2px #152954;
}

.text-horse-name {
    font-family: 'Racing Sans One', cursive;
    text-shadow: 0.09rem 0.09rem 0.2rem #092a00;
    color: #ffe900;
    font-size: 1.1rem;
    line-height: 1.2rem;
    letter-spacing: 0.15rem;
    -webkit-text-stroke: 0.01rem #acd9ac;
    min-height: 35px;
    text-stroke: 0.01rem #144b14;
    margin-bottom: 1rem;
}

.text-horse-name-list {
    font-family: 'Racing Sans One', cursive;
    text-shadow: 0.09rem 0.09rem 0.2rem #092a00;
    color: #ffe900;
    font-size: 1.2rem;
    line-height: 15px;
    letter-spacing: 1px;
    -webkit-text-stroke: 0.01rem #99d799;
    text-stroke: 0.01rem #99d799;
}

.balance {
    font-family: 'Oswald', sans-serif;
    font-size: 0.8rem;
    letter-spacing: 1px;
}

.login {
    font-family: 'Oswald', sans-serif;
    font-size: 0.8rem;
    letter-spacing: 1px;
}

.info {
    font-family: 'Oswald', sans-serif;
    color: #144b14;
}

.bg-gradient-green {
    background: linear-gradient(0deg, #DFEC51 0%, #8dc256 100%);
}

.bg-gradient-yellow {
    background: linear-gradient(0deg, #fff720 0%, #8dc256 100%);
}

.bg-gradient-yellow-backward {
    background: linear-gradient(0deg, #8dc256 0%, #fff720 100%);
}

.bg-gradient-orange {
    background: linear-gradient(130deg, #fad961 0%, #f76b1c 100%);
}

.properties_list_item {
    width: 55px;
    height: 20px;
    display: inline-block;
    padding: 0;
}

.ms--2 {
    margin-left: -10px;
}

.mt--5 {
    margin-top: -5px !important;
}

.mt--10 {
    margin-top: -50px !important;
}

.bg-top-header {
    background-color: #8dc256 !important;
    /*border:none;*/
    border-color: rgb(113, 162, 65) !important;
}

.shadow {
    box-shadow: 0.2rem 0.2rem 0.5rem #2c440f !important;
}

.Toastify__toast-icon {
    width: 64px;
    height: 64px;
}

.Toastify__toast-body > div:last-child {
    font-size: 1.3rem
}